import React from "react"
import { graphql } from "gatsby"
import {Layout} from "@components/Layout"
import {Banner,mapToPageHeader} from "@components/Banner"
import {SEO,mapToSEO} from '@components/SEO'
import { SignupBannerWrapper,mapToKiwiVIPVisible} from "@components/SignupBanner";
import { GenericBannerContainer } from "@components/GenericBannerContainer"
import { mapToImageInfoBanner, ImageInformationBanner } from "@components/ImageInformationBanner"
import { mapToPricingImageCardContainer } from "@mappers/PricingImageCard/PricingImageCard.mapper"
import PricingImageCardContainer from "@components/PricingImageCard/PricingImageCardContainer"
import { mapToImageGridContainer } from "@mappers/ImageGrid/ImageGrid.mapper"
import ImageGridContainer from "@components/ImageGrid/ImageGridContainer"
import { ImageButtonBanner, mapToImageLinkBanner } from "@components/ImageButtonBanner"
import { mapToFaqContainer } from "@components/FaqList/mapper"
import { FaqContainer } from "@components/FaqList/FaqList.styles"
import { FaqList } from "@components/FaqList"
import theme from "@styles/theme"
import { RestyleCombinationFinder } from "@components/CombinationFinder"
import { getCleanCampaignByQueryString, getVehicleTypeByQueryString } from "@utils/Helpers"
import { mapToCampaignItems, mapToRestyleCombinationFinder } from "@mappers/RestyleCombinationFinder/RestyleCombinationFinder.mapper"
import KpButton from "@elements/KpButton/KpButton"
import { LOCALSTORAGE_CAMPAIGN_CURRENT } from "@redux/localStorage"

const queryString = require('query-string');
type RedesignState = {
  cleanCampaign: boolean
}

const loadFromQuerystring = (location: any):RedesignState =>{
  if(location.search){
    const query = queryString.parse(location.search,{parseBooleans: true, parseNumbers: true});
    const cleanCampaign = getCleanCampaignByQueryString(query.clean)

    return {
      cleanCampaign: cleanCampaign
    };
  }
  return {
    cleanCampaign: false
  }
}

const loadFromStates = (states:any):RedesignState | undefined =>{
  if(states){
    return {
      cleanCampaign: states.cleanCampaign
    }
  }
  return undefined;
}

const RestyleLandingPage = (props:any) => {
    const {location} = props;
    const {elements} = props.data.kontentItemRestyleLandingPage;
    const seo = mapToSEO(elements);
    const apiUrl = props.data.site.siteMetadata.apiUrl;
    const kiwiVip = mapToKiwiVIPVisible(elements);
    const metadata = mapToPageHeader(elements);
    const infoBannerData = mapToImageInfoBanner(elements.info_image_banner.linked_items[0].elements)
    const pricingImageContainerData = mapToPricingImageCardContainer(elements.pricing_image_cards.linked_items[0].elements)
    const imageGridContainerTopData = mapToImageGridContainer(elements.image_grid___top.linked_items[0].elements)
    const imageGridContainerBottomData = mapToImageGridContainer(elements.image_grid___bottom.linked_items[0].elements)
    const restyleCombinationFinderData = mapToRestyleCombinationFinder(elements.restyle_combination_finder.linked_items[0].elements)
    if(restyleCombinationFinderData.campaigns.length == 0){
      restyleCombinationFinderData.campaigns.push(mapToCampaignItems(props.data.allKontentItemSweepstakeCampaign))
    }

    const states = loadFromStates(location.state);
    const queryData = states === undefined ? loadFromQuerystring(location) : states;

    restyleCombinationFinderData.headingAlignment = 'center';
    restyleCombinationFinderData.apiUrl = apiUrl;
    restyleCombinationFinderData.reCaptchaSiteKey = props.data.site.siteMetadata.reCaptchaSiteKey;
    restyleCombinationFinderData.reCaptchaSiteKeyV2 = props.data.site.siteMetadata.reCaptchaSiteKeyV2;
    restyleCombinationFinderData.emailValidationToken = props.data.site.siteMetadata.emailValidationToken;
    const faqData = mapToFaqContainer(elements.faq_s.linked_items[0].elements)
    const imageButtonBannerData = mapToImageLinkBanner(elements.image_button_banner.linked_items[0].elements)
    if (queryData.cleanCampaign){
      const campaignInLocal: string | null = localStorage.getItem(LOCALSTORAGE_CAMPAIGN_CURRENT);
      if(campaignInLocal) {
        localStorage.removeItem(LOCALSTORAGE_CAMPAIGN_CURRENT); 
      }     
    }

    return (<Layout location={props.location}>              
        <SEO {...seo} />
        <Banner {...metadata} isBiggerBanner={false}  />
        {/* <GenericBannerContainer 
          padding={{
            mobile: {
                top: 50,
                bottom: 54
            },
            desktop: {
                top: 86,
                bottom: 80
            }
          }}
          backgroundColor={infoBannerData.backgroundColor} removeContainer={true}>
          <ImageInformationBanner verticalPosition='middle' {...infoBannerData}/>
        </GenericBannerContainer> */}
        {/* <GenericBannerContainer backgroundColor={imageGridContainerTopData.backgroundColour} paddingTop={100} paddingBottom={100}>
          <ImageGridContainer {...imageGridContainerTopData}/>
        </GenericBannerContainer>
        <GenericBannerContainer backgroundColor={pricingImageContainerData.backgroundColour} paddingTop={100} paddingBottom={100}>
          <PricingImageCardContainer {...pricingImageContainerData}/>
        </GenericBannerContainer> */}
        <GenericBannerContainer 
          padding={{
            mobile: {
                top: 50,
                bottom: 60
            },
            desktop: {
                top: 90,
                bottom: 73
            }
          }}
          backgroundColor={theme.brand.colors.beige}>
          <RestyleCombinationFinder {...restyleCombinationFinderData} darkTheme={false}/>
        </GenericBannerContainer>
        <GenericBannerContainer 
          padding={{
            mobile: {
                top: 65,
                bottom: 60
            },
            desktop: {
                top: 103,
                bottom: 100
            }
          }}
          backgroundColor={imageGridContainerBottomData.backgroundColour}>
          <ImageGridContainer {...imageGridContainerBottomData}/>
          <div style={{textAlign: 'center', width: '100%'}}>
            <KpButton fullWidth='mobile' id='BrowseAllStyles' buttonType='primary' color={theme.brand.colors.blue} link='/plate-designs/'>BROWSE ALL STYLES</KpButton>
          </div>
        </GenericBannerContainer>
        <GenericBannerContainer padding={{mobile: {top: 50,bottom: 60},desktop: {top: 90,bottom: 100}}} backgroundColor={faqData.backgroundColor}>
          <FaqList borderColor={theme.brand.colors.black} questionColor={theme.brand.colors.black} answerColor={theme.brand.colors.black} {...faqData} />
        </GenericBannerContainer>
        <ImageButtonBanner {...imageButtonBannerData} />
        {
            kiwiVip.visible && 
            <SignupBannerWrapper version= {kiwiVip.version} />
        }
  </Layout>);
  
}

export const query = graphql`  
{
  site {
    siteMetadata {
      apiUrl
      emailValidationToken
      reCaptchaSiteKey
      reCaptchaSiteKeyV2
    }
  }
  kontentItemRestyleLandingPage {
    elements {
      restyle_combination_finder {
        linked_items {
          ... on KontentItemRestyleCombinationFinder {
            id
            elements {
              available_message {
                value
              }
              button__button_color_theme {
                value {
                  codename
                  name
                }
              }
              button__button_type {
                value {
                  codename
                  name
                }
              }
              button__text {
                value
              }
              button__url_slug {
                value
              }
              heading {
                value
              }
              restyle_guide_message {
                value
              }
              restyle_options {
                linked_items {
                  ... on KontentItemProductCategoryFormats {
                    id
                    elements {
                      base_price {
                        value
                      }
                      description {
                        value
                      }
                      example_image {
                        value {
                          url
                          description
                        }
                      }
                      format_type {
                        value {
                          name
                          codename
                        }
                      }
                      title {
                        value
                      }
                    }
                  }
                }
              }
              sub_heading {
                value
              }
              unavailable_message {
                value
              }
              alternative_message{
                value
              }
              campaign_items {
                linked_items {
                  ... on KontentItemSweepstakeCampaign {
                    elements {
                      campaign_name {
                        value
                      }
                      expire_on {
                        name
                        type
                        value
                      }
                      valid_from {
                        name
                        type
                        value
                      }                        
                      transaction_type {
                        value {
                          name
                          codename
                        }
                      }                        
                      ads {
                        linked_items {
                          ... on KontentItemCampaignAds {
                            elements {
                              left_title_1 {
                                value
                              }
                              left_title_2 {
                                value
                              }
                              left_description {
                                value
                              }
                              left_picture {
                                value {
                                  name
                                  description
                                  url
                                  width
                                  height
                                }
                              }
                              right_title {
                                value
                              }
                              right_description_1 {
                                value
                              }
                              right_description_2 {
                                value
                              }
                              right_description_3 {
                                value
                              }
                              left_background_colors {
                                value {
                                  name
                                  codename
                                }
                              }
                              right_background_colors {
                                value {
                                  name
                                  codename
                                }
                              }
                            }
                          }
                        }
                      }
                      popup {
                        linked_items {
                          ... on KontentItemCampaignPopup {
                            elements {
                              header {
                                value
                              }
                              header_campaign_name{
                                value
                              }
                              picture {
                                value {
                                  name
                                  description
                                  url
                                  width
                                  height
                                }
                              }                              
                              form_title {
                                value
                              }
                              email_opt_in_text{
                                value
                              }
                              footer {
                                value
                              }
                              terms___conditions{
                                value
                              }
                              background_color {
                                value {
                                  name
                                  codename
                                }
                              }
                              response {
                                value
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      faq_s {
        linked_items {
          ... on KontentItemFaqBlock {
            id
            elements {
              background_colors {
                value {
                  codename
                  name
                }
              }
              title {
                value
              }
              description{
                value
              }
              faqs {
                linked_items {
                  ... on KontentItemFaqItem {
                    id
                    elements {
                      answer {
                        value
                      }
                      faq_category {
                        value {
                          codename
                          name
                        }
                      }
                      question {
                        value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      general_page_content__header_image {
        value {
          url
          description
        }
      }
      general_page_content__responsive_header_image {
        linked_items {
          ... on KontentItemBannerImage {
            id
            elements {
              desktop {
                value {
                  url
                  description
                }
              }
              mobile {
                value {
                  description
                  url
                }
              }
              tablet {
                value {
                  description
                  url
                }
              }
            }
          }
        }
      }
      general_page_content__kiwivip_signup {
        value {
          codename
          name
        }
      }
      general_page_content__kiwivip_signup_version {
        value {
          codename
          name
        }
      }
      general_page_content__title {
        value
      }
      general_page_content__title_position {
        value {
          codename
          name
        }
      }
      image_button_banner {
        linked_items {
          ... on KontentItemImageLinkBanner {
            elements {
              button__button_color_theme {
                value {
                  codename
                  name
                }
              }
              button__button_type {
                value {
                  codename
                  name
                }
              }
              button__text {
                value
              }
              button__url_slug {
                value
              }
              image {
                value {
                  url
                  description
                }
              }
              title {
                value
              }
            }
          }
        }
      }
      image_grid___top {
        linked_items {
          ... on KontentItemImageGridContainer {
            id
            elements {
              background_colors {
                value {
                  codename
                  name
                }
              }
              heading {
                value
              }
              sub_heading {
                value
              }
              image_grid_items {
                linked_items {
                  ... on KontentItemImageGridItem {
                    id
                    elements {
                      body {
                        value
                      }
                      heading {
                        value
                      }
                      image {
                        value {
                          url
                          description
                        }
                      }
                      url {
                        value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      image_grid___bottom {
        linked_items {
          ... on KontentItemImageGridContainer {
            id
            elements {
              background_colors {
                value {
                  codename
                  name
                }
              }
              heading {
                value
              }
              sub_heading {
                value
              }
              image_grid_items {
                linked_items {
                  ... on KontentItemImageGridItem {
                    id
                    elements {
                      body {
                        value
                      }
                      url {
                        value
                      }
                      heading {
                        value
                      }
                      image {
                        value {
                          url
                          description
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      info_image_banner {
        linked_items {
          ... on KontentItemInfoImageBanner {
            id
            elements {
              background_colors {
                value {
                  codename
                  name
                }
              }
              button__button_color_theme {
                value {
                  codename
                  name
                }
              }
              button__button_type {
                value {
                  codename
                  name
                }
              }
              button__text {
                value
              }
              button__url_slug {
                value
              }
              description {
                value
              }
              image {
                value {
                  url
                  description
                }
              }
              image_description {
                value
              }
              info_image_banner___image_position {
                value {
                  codename
                  name
                }
              }
              text_colors {
                value {
                  codename
                  name
                }
              }
              title {
                value
              }
            }
          }
        }
      }
      pricing_image_cards {
        linked_items {
          ... on KontentItemPricingImageCardContainer {
            id
            elements {
              background_colors {
                value {
                  codename
                  name
                }
              }
              heading {
                value
              }
              sub_heading {
                value
              }
              pricing_image_cards {
                linked_items {
                  ... on KontentItemPricingImageCard {
                    id
                    elements {
                      body {
                        value
                      }
                      button__button_color_theme {
                        value {
                          codename
                          name
                        }
                      }
                      button__button_type {
                        value {
                          codename
                          name
                        }
                      }
                      button__text {
                        value
                      }
                      button__url_slug {
                        value
                      }
                      heading {
                        value
                      }
                      image {
                        value {
                          url
                          description
                        }
                      }
                      price {
                        value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      general_page_content__responsive_header_image {
        linked_items {
          ... on KontentItemBannerImage {
            id
            elements {
              desktop {
                value {
                  url
                  description
                }
              }
              mobile {
                value {
                  description
                  url
                }
              }
              tablet {
                value {
                  description
                  url
                }
              }
            }
          }
        }
      }
      seo__nofollow {
        value {
          codename
          name
        }
      }
      seo__noindex {
        value {
          codename
          name
        }
      }
      seo__page_description {
        value
      }
      seo__page_title {
        value
      }
      url {
        value
      }
    }
  }
  allKontentItemSweepstakeCampaign {
    nodes {
      elements {
        campaign_name {
          value
        }
        expire_on {
          name
          type
          value
        }
        valid_from {
          name
          type
          value
        }
        transaction_type {
          value {
            name
            codename
          }
        }
        ads {
          linked_items {
            ... on KontentItemCampaignAds {
              elements {
                left_title_1 {
                  value
                }
                left_title_2 {
                  value
                }
                left_description {
                  value
                }
                left_picture {
                  value {
                    name
                    description
                    url
                    width
                    height
                  }
                }
                right_title {
                  value
                }
                right_description_1 {
                  value
                }
                right_description_2 {
                  value
                }
                right_description_3 {
                  value
                }
                left_background_colors {
                  value {
                    name
                    codename
                  }
                }
                right_background_colors {
                  value {
                    name
                    codename
                  }
                }
              }
            }
          }
        }
        popup {
          linked_items {
            ... on KontentItemCampaignPopup {
              elements {
                header {
                  value
                }
                header_campaign_name{
                  value
                }
                picture {
                  value {
                    name
                    description
                    url
                    width
                    height
                  }
                }                              
                form_title {
                  value
                }
                email_opt_in_text{
                  value
                }
                footer {
                  value
                }
                terms___conditions{
                  value
                }
                background_color {
                  value {
                    name
                    codename
                  }
                }
                response {
                  value
                }
                input_field_background_color {
                  value {
                    name
                    codename
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`
export default RestyleLandingPage;
